.input_container--outer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;

  .label {
    font-weight: 500;
    color: #808080;
  }

  .input_container--inner {
    display: flex;
    align-items: center;
    border-radius: 0;
    border: 1px solid #2d2d2d;
    padding-right: 20px;
    border-width: 0 0 1px 0;
    &:focus-within {
      border: 2px solid #169fed;
      border-width: 0 0 1px 0;
    }
    &:hover {
      border-color: #169fed;
    }

    input {
      display: flex;
      flex: 1;
      align-items: center;
      padding: 0 0.875rem;
      border: none;
      font-size: 16px; //handling text zoom
      height: 48px;
      max-height: 48px;
      font-weight: 500;
      border-radius: 0.5rem;
      color: rgba($color: #000000, $alpha: 0.6);

      &:focus {
        outline: none !important;
        border-width: 0 0 1px 0;
      }

      &:disabled {
        opacity: 80%;
      }
    }
  }
}
