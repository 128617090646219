.about_me--container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  
  // Label styling
  .label {
    font-weight: 600;
    color: #808080;
    font-size: 14px;

    @media (max-width: 768px) {
      font-size: 12px;
    }
  }
  
  // Value styling
  .value {
    font-size: 16px;
    color: #000000;
    font-weight: 500;

    @media (max-width: 768px) {
      font-size: 14px;
    }
  }

  // Field container for layout
  .field-container {
    margin: 10px 0;
    display: flex;
    flex-direction: column;
    gap: 2px;

    @media (max-width: 768px) {
      margin: 5px 0;
    }

    // Languages layout
    .languages {
      display: flex;
      gap: 12px;
      flex-wrap: wrap;

      .language {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 8px; // Adjust padding
        gap: 8px;
        border: 1px solid #e1e8ed;
        border-radius: 4px;

        @media (max-width: 768px) {
          padding: 6px;
          gap: 6px;
        }

        &-name {
          font-size: 12px;
          color: #000;
          font-weight: 500;
          text-transform: capitalize;

          @media (max-width: 768px) {
            font-size: 11px;
          }
        }
      }
    }
  }

  // About me section
  .about-me-section {
    font-size: 14px;
    color: #333;
    line-height: 1.5;

    @media (max-width: 768px) {
      font-size: 12px;
    }
  }

  // Languages container
  .languages-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;

    .icon-container {
      width: 84px;
      display: flex;

      @media (max-width: 768px) {
        width: 60px;
      }
    }

    .languages {
      display: flex;
      gap: 12px;
      flex-wrap: wrap;

      .language {
        border: none;

        &-name {
          font-size: 14px;
          color: #000;

          @media (max-width: 768px) {
            font-size: 12px;
          }
        }

        &-proficiency {
          position: relative;
          top: -10px;
          right: 2px;
          font-size: 12px;
          color: #169fed;

          @media (max-width: 768px) {
            top: -5px;
            font-size: 10px;
          }
        }
      }
    }
  }
}
