.verdict_container {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1;
  height: 171px;
  color: white;
  padding: 16px;
  width: 100dvw;

  button {
    max-width: 343px;
  }

  &[data-is-answer="false"] {
    background-color: #ff5252;

    button {
      color: #ff5252 !important;
    }
  }

  &[data-is-answer="true"] {
    background-color: #0ac68b;

    button {
      color: #0ac68b !important;
    }
  }

  .inner_container {
    max-width: 1360px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .is_answer {
      display: flex;
      gap: 14px;
      align-items: center;
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 18px;
    }

    .answer {
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 18px;
    }
  }

  @media (min-width: 768px) {
    padding: 28px;
    height: 320px;

    button {
      max-width: 400px;
    }

    .inner_container {
      flex-direction: row;
      align-items: flex-end;
      .is_answer {
        gap: 36px;
        font-size: 18px;
        margin-bottom: 24px;
      }
      .answer {
        font-size: 24px;
        margin-bottom: 18px;
      }
    }
  }
}
