.search_input--container {
  display: flex;
  align-items: center;
  padding: 0 24px;
  background: #ffffff;
  border: 2px solid #e1e8ed;
  border-radius: 10px;

  input {
    display: block;
    flex: 1 1 0%;
    height: 56px;
    border: none;
    padding-left: 20px;

    &:focus {
      outline: none;
      border: none;
    }
  }
}
