button.custom-btn {
  display: flex;
  justify-content: center;
  font-family: "Poppins";
  align-items: center;
  cursor: pointer;
  &:disabled {
    opacity: 60%;
  }

  &[data-variant="primary"] {
    background: #169fed;
    color: #fff;
    padding: 12px 20px;
    border-width: 0.731203px 1.46241px 2.92481px 1.46241px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.2);
    border-radius: 14.6241px;
    width: 100%;
    font-size: 18px;
    font-weight: 500;
    height: 48px;

    &:disabled {
      background: #b3b3b3;
    }
  }

  &[data-variant="secondary"] {
    box-sizing: border-box;
    background: #ffffff;
    border-width: 0.7px 1.5px 3px 1.5px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.2);
    border-radius: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 48px;
    color: #169fed;
    font-weight: 500;
    font-size: 18px;
    text-decoration: none;
  }

  &[data-variant="neutral"] {
    background: inherit;
    border: none;

    &:disabled {
      cursor: not-allowed;
    }
  }
}
