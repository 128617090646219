.single_activity--container {
  max-width: 1360px;
  max-height: 100vh;
  position: relative;
  overflow: hidden;
  margin: auto;
  padding: 20px 16px;

  .top {
    display: flex;
    gap: 20px;
    margin-bottom: 48px;
  }

  @media (max-width: 767px) {
    height: 100vh;
  }

  @media (min-width: 768px) {
    padding: 20px;
  }
}
