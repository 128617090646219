.go-back {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  margin-bottom: 30px;
}
.schedule-lesson {
  background: white;
  border-radius: 12px;
  max-width: 660px;
  padding: 40px 40px;
  border: 1px solid #e1e8ed;

  .first-row {
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .date {
      font-weight: 700;
      color: black;
      margin: 0;
      margin-bottom: 12px;
    }

    .time {
      color: #808080;
      font-weight: 500;
      font-size: 20px;
    }

    .language {
      font-size: 16px;
      font-weight: 500;
      color: #1491d8;
    }
  }
  .imageProfile {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .profile {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  .dpImage {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    margin-right: 10px;
  }
  .name {
    font-weight: 500;
  }
  .message {
    border: 1px solid #169fed;
    color: #169fed;
    padding: 10px 20px;
    border-radius: 20px;
    font-weight: 600;
  }
  .timeDiff {
    color: #169fed;
  }
  .teacher {
    .title {
      margin-bottom: 4px;
      color: #808080;
      font-size: 12px;
    }
    .description {
      color: black;
      font-size: 14px;
    }
  }

  .lesson {
    display: block;
    margin: 48px 0;

    .lesson--title {
      font-weight: 600;
      color: black;
      font-size: 14px;
    }

    .title {
      margin-bottom: 4px;
      color: #808080;
      font-size: 12px;
    }

    .description {
      color: #808080;
      font-size: 14px;
    }
  }

  .classroom {
    margin-bottom: 64px;
    display: flex;
    gap: 24px;
    justify-content: space-between;

    .column {
      display: flex;
      flex-direction: column;
      gap: 4px;
      flex-basis: 33%;

      .bottom {
        display: flex;
        align-items: center;
        gap: 10px;
        font-size: 14px;
      }
    }
  }

  button.action {
    max-width: 160px;
    font-weight: 500;
    font-size: 12px;
  }
  button.report {
    max-width: 160px;
    font-weight: 500;
    font-size: 13px;
    margin-left: 10px;
    background-color: #ff5252;
    color: #fff;
  }
}

.loading {
  width: 100dvw;
  height: 100dvh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btnContainer {
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 768px) {
  .classroom {
    margin-bottom: 64px;
    display: flex;
    gap: 24px;
    justify-content: space-between;
    flex-direction: column;

    .column {
      display: flex;
      flex-direction: column;
      gap: 4px;
      flex-basis: 33%;

      .bottom {
        display: flex;
        align-items: center;
        gap: 10px;
        font-size: 14px;
      }
    }
  }
}
