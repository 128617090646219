.password-settings {
  .inputs {
    display: grid;
    gap: 24px;
  }

  .back-btn {
    display: flex;
    gap: 12px;
    align-items: center;
    justify-content: start;
    width: fit-content;
    color: #000;
  }

  .continue_btn--container {
    display: flex;
    justify-content: flex-end;
    margin-top: 24px;

    button {
      width: 112px;
      height: 38px;
      background: #169fed;
      border-radius: 40px;
      color: white;
      font-weight: 500;
    }
  }
}
