.signin {
  padding: 20px;
  background: #ffffff;
  box-shadow: 0px 4px 65px rgba(0, 0, 0, 0.13);
  max-height: 100svh;
  height: 100%;

  .go-back {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 56px;
  }

  .headings {
    h1 {
      text-align: center;
    }

    h3 {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      max-width: 400px;
      line-height: 18px;
      color: #808080;
      //   text-align: center;
      margin: auto;
      margin-bottom: 32px;
    }
  }

  h6 {
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
  }
}
