.header {
  height: 90px;
  display: flex;
  align-items: center;
  border-bottom: 2px solid rgba(128, 128, 128, 0.4);
  font-size: 20px;
  font-weight: 500;
  padding-left: 20px;
  padding-right: 140px;

  .greeting {
    align-items: center;
    flex: 2;
    font-size: 17px;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .description {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .language-and-arrow {
    display: flex;
    gap: 10px;
    align-items: center;
    border: 2px solid rgba(128, 128, 128, 0.4);
    padding: 10px;
    border-radius: 20px;
  }
}
.languageTopContainer {
  background-color: white;
  padding: 12px 20px;
  overflow: scroll;
  height: 308px;
  max-width: 600px;
  width: 34%;
  position: absolute;
  right: 14%;
  top: 140px;
  border: 2px solid rgba(128, 128, 128, 0.4);
  border-radius: 10px;
  z-index: 1000;
}
.langTextContain {
  padding: 8px 0;
}
.langText {
  font-size: 15px;
  font-weight: 500;
}
.searchInput {
  width: 100%;
  padding: 8px;
  margin-bottom: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

.langTextContain {
  padding: 8px;
  cursor: pointer;
  border-bottom: 1px solid #f0f0f0;
}

.langTextContain:hover {
  background-color: #f7f7f7;
}
.closeButton {
  display: flex;
  align-self: flex-end;
  justify-self: flex-end;
  margin-bottom: 10px;
  border: none;
  background-color: transparent;
}
.langText {
  margin: 0;
  font-size: 14px;
  color: #333;
}
@media (max-width: 768px) {
  .header {
    height: 90px;
    display: flex;
    align-items: center;
    border-bottom: 2px solid rgba(128, 128, 128, 0.4);
    font-size: 20px;
    font-weight: 500;
    padding-left: 20px;
    padding-right: 30px;
  }

  .greeting {
    align-items: center;
    flex: 2;
  }

  .description {
    display: flex;
    align-items: center;
    gap: 30px;
  }

  .language-and-arrow {
    display: flex;
    gap: 10px;
    align-items: center;
    border: 2px solid rgba(128, 128, 128, 0.4);
    padding: 10px;
    border-radius: 20px;
  }
  .languageTopContainer {
    background-color: white;
    position: absolute;
    padding: 8px 14px;
    right: 3%;
    top: 122px;
    width: 95vw;
    border: 2px solid rgba(128, 128, 128, 0.4);
    height: 100vh;
    overflow: scroll;
  }
  .langTextContain {
    padding: 8px 0;
  }
  .langText {
    font-size: 14px;
    font-weight: 400;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .languageTopContainer {
    background-color: white;
    padding: 12px 20px;
    overflow: scroll;
    height: 308px;
    max-width: 600px;
    width: 34%;
    position: absolute;
    right: 7%;
    top: 140px;
    border: 2px solid rgba(128, 128, 128, 0.4);
    border-radius: 10px;
  }
}
