.teacher_card--container {
  border-width: 2px 4px 6px 4px;
  border-style: solid;
  border-color: #e1e8ed;
  border-radius: 10.48px;

  .teacher-main {
    padding: 30px;
    border-bottom: 2px solid #e1e8ed;

    .profile {
      display: flex;
      gap: 20px;
      margin-bottom: 12px;

      img {
        width: 84px;
        height: 84px;
        border-radius: 9999px;
        object-fit: cover;
      }

      &-right {
        h1 {
          font-size: 16px;
          cursor: pointer;
        }

        h3 {
          font-size: 16px;
          color: #4f5153;
          font-weight: 500;
          text-transform: capitalize;
          margin-bottom: 4px;
        }

        .interests {
          display: flex;
          gap: 14px;
          flex-wrap: wrap;

          .interest {
            border: 1.75px solid #e1e8ed;
            padding: 4px 10px;
            border-radius: 2px;
            font-size: 12px;
            color: #000;
            font-weight: 500;
            text-transform: capitalize;
            border-radius: 6px;
          }
        }
      }
    }

    .languages-container {
      display: flex;
      gap: 24px;
      align-items: center;

      .icon-container {
        width: 84px;
        display: flex;
        justify-content: center;
      }
      .languages {
        display: flex;
        gap: 12px;
        flex-wrap: wrap;

        .language {
          &-name {
            font-size: 14px;
            color: #000;
            text-transform: capitalize;
          }

          &-proficiency {
            position: relative;
            top: -10px;
            font-size: 12px;
            color: #169fed;
          }
        }
      }
    }

    .bio {
      font-size: 12px;
      font-weight: 500;
      color: #000;
    }
  }

  .teacher-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
    margin-top: 15px;

    .price-container {
      span {
        display: block;
      }

      .title {
        color: #808080;
        font-size: 12px;
      }

      .price {
        font-size: 16px;
        font-weight: 600;
        color: #000;
      }
    }

    .btn-containers {
      display: flex;
      align-items: center;
      gap: 14px;

      button {
        width: 111.79px;
        height: 41.92px;
        border-radius: 10.48px;
        font-weight: 500;

        &.send-btn {
          background: #169fed;
          color: white;
        }

        &.msg-btn {
          background: #ffffff;
          color: #169fed;
          border: 1.74667px solid #169fed;
        }
      }
    }
  }

  @media screen and (max-width: 640px) {
    .teacher-main {
      padding: 15px;
      .languages-container {
        gap: 10px;
        margin-bottom: 10px;
      }
    }
    .teacher-bottom{
      padding: 10px 15px;
    }
     .price-container {
      .title {
        color: #808080;
        font-size: 10px;
      }

      .price {
        font-size: 14px;
      }
    }

    .btn-containers {
      display: flex;
      align-items: center;
      gap: 8px;

      button {
        border-radius: 10.48px;
        font-weight: 500;

        &.send-btn {
          background: #169fed;
          color: white;
           width: 85px;
        height: 41.92px;
        }

        &.msg-btn {
          background: #ffffff;
          color: #169fed;
          border: 1.74667px solid #169fed;
           width: 85px;
        height: 41.92px;
        }
      }
    }
  }
}
