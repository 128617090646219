.class-type-container {
  padding: 20px;

  .icon-text {
    border: 1px solid #c4c4c4;
    padding: 20px 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 10px;
    margin-bottom: 25px;
  }
  .class-text {
    font-weight: 500;
    font-size: 16px;
    margin-left: 20px;
  }
}
