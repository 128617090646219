.teacher_card--container {
  border-width: 2px 4px 6px 4px;
  border-style: solid;
  border-color: #e1e8ed;
  border-radius: 10.48px;

  .top {
    height: 232px;
    border-top-left-radius: 10.48px;
    border-top-right-radius: 10.48px;
    background-color: #b7e1f9;
    display: flex;
    // align-items: fl;
    justify-content: flex-start;
    color: #169fed;
    width: calc(100% + 8px);
    transform: translateX(-4px);
    position: relative;
    overflow: hidden;
  }

  .main {
    padding: 40px 20px 0 40px;
    border-bottom: none;

    .profile {
      display: flex;
      gap: 24px;
      margin-bottom: 20px;

      img {
        width: 84px;
        height: 84px;
        border-radius: 9999px;
        object-fit: cover;
      }

      &-right {
        h1 {
          font-size: 16px;
          cursor: pointer;
        }

        h3 {
          font-size: 16px;
          color: #4f5153;
          font-weight: 500;
          text-transform: capitalize;
          margin-bottom: 4px;
        }

        .interests {
          display: flex;
          gap: 14px;
          flex-wrap: wrap;

          .interest {
            border: 1.75px solid #e1e8ed;
            padding: 4px 10px;
            border-radius: 2px;
            font-size: 12px;
            color: #000;
            font-weight: 500;
            text-transform: capitalize;
            border-radius: 6px;
          }
        }
      }
    }

    .languages-container {
      display: flex;
      gap: 24px;
      align-items: center;
      margin-bottom: 16px;

      .icon-container {
        width: 84px;
        display: flex;
        justify-content: start;
      }
      .languages {
        display: flex;
        gap: 12px;
        flex-wrap: wrap;

        .language {
          &-name {
            font-size: 14px;
            color: #000;
            text-transform: capitalize;
          }

          &-proficiency {
            position: relative;
            top: -10px;
            font-size: 12px;
            color: #169fed;
          }
        }
      }
    }

    .speaks-container {
      display: flex;
      gap: 24px;
      align-items: center;
      margin-bottom: 16px;

      .icon-container {
        width: 84px;
        display: flex;
      }

      .languages {
        display: flex;
        gap: 12px;
        flex-wrap: wrap;

        .language {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 10.48px;
          gap: 8.73px;
          height: 27.95px;
          border: 1.74667px solid #e1e8ed;
          border-radius: 3.49333px;

          &-name {
            font-size: 12px;
            color: #000;
            font-weight: 500;
            text-transform: capitalize;
          }
        }
      }
    }
    .interests-container {
      display: flex;
      gap: 24px;
      align-items: center;
      margin-bottom: 16px;

      .icon-container {
        width: 84px;
        display: flex;
      }

      .languages {
        display: flex;
        gap: 12px;
        flex-wrap: wrap;

        .language {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 10.48px;
          gap: 8.73px;
          height: 27.95px;
          border: 1.74667px solid #e1e8ed;
          border-radius: 3.49333px;

          &-name {
            font-size: 12px;
            color: #000;
            font-weight: 500;
            text-transform: capitalize;
          }
        }
      }
    }
  }

  .bottom {
    display: grid;
    grid-template-columns: 130px 1fr;
    align-items: center;
    padding: 20px 40px;
    column-gap: 12px;

    button {
      width: 100%;
      height: 41.92px;
      border-radius: 10.48px;
      font-weight: 500;

      &.send-btn {
        background: #169fed;
        color: white;
      }

      &.msg-btn {
        background: #ffffff;
        color: #169fed;
        border: 1.74667px solid #169fed;
      }
    }
  }
}
.youtube-pluri-logo {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
