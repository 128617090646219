.react-calendar {
  width: 100%;
  font-family: "Poppins" !important;
  border: none;

  button {
    font-family: "Poppins" !important;
  }

  .react-calendar__navigation {
    display: flex;
    align-items: center;
    height: auto;
    width: 80%;
    margin: auto;
    margin-bottom: 28px;

    &__arrow {
      width: 32px;
      min-width: 32px !important;
      height: 32px;
      background-color: #169fed;
      border-radius: 99999px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &__label {
      font-size: 18px;
      font-weight: 600;
      color: black;
    }
  }

  .react-calendar__month-view__weekdays {
    margin-bottom: 8px;
  }
  .react-calendar__month-view__weekdays__weekday {
    abbr {
      color: #c4c4c4;
      font-size: 16px;
      border: none;
      text-decoration: none;
      text-transform: none;
    }
  }

  .react-calendar__month-view__days__day {
    abbr {
      font-size: 18px;
      font-weight: 500;
      color: #131a29;
    }
    &--neighboringMonth {
      abbr {
        color: #c4c4c4;
      }
    }
    &--weekend {
      color: #131a29;
    }

    /* Hover effect for day tiles */
    &:hover {
      background-color: #f0f0f0; /* Gray background color on hover */
      cursor: pointer;
    }
  }

  .react-calendar__tile--now {
    background: none;
  }

  /* Custom styles for available and unavailable dates */
  .available-date {
    background-color: transparent; /* Green for available dates */
    color: white;
  }

  .unavailable-date {
    background-color: #e4e2e2; /* Red for unavailable dates */
    color: white;
  }

  /* Style for the selected date */
  .react-calendar__tile--active {
    background-color: #cbcfd16f; /* Blue background for the selected day */
    color: #fff; /* White text color for the selected day */
  }
  .tile-active {
    background-color: #2d93ce6f; /* Blue background for the selected day */
    color: #fff; /* White text color for the selected day */
  }
}
.availableText {
  font-size: 18px;
  font-weight: 500;
  color: #131a29;
}
.time-container {
  border: 1px solid #e1e8ed;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
  padding: 10px 40px;
  margin: 8px 0 0 8px;
}
