.teachers--container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: start;
  gap: 40px;
  padding-bottom: 80px;

  @media screen and (max-width: 1024px) {
    grid-template-columns: repeat(1, 1fr);
  }

  & > .left {
    max-width: 485px;
    display: grid;
    gap: 40px;
  }

  & > .hidden-on-mobile {
    @media (max-width: 1024px) {
      display: none;
    }
  }

  & > .right {
    max-width: 485px;
    &[data-show="true"] {
      @media screen and (max-width: 768px) {
        padding: 20px;
        background-color: white;
        z-index: 10;
        display: block;
        height: 100%;
        width: 100%;
        overflow-y: auto;
      }
    }
  }
}

.stats-container {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  margin: 0 20px;
}
