.list-edit--container {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .heading {
    font-weight: 700;
    color: #808080;
    font-size: 1rem;
    margin: 20px 0;
  }

  .list {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;

    .list-item {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 12px 24px;
      height: 48px;
      border: 2px solid #e1e8ed;
      border-radius: 12px;
      color: #000;
      font-weight: 500;

      &[data-selected="true"] {
        background-color: #169fed;
        color: white;
      }
    }
  }
}
