.question_container {
  width: 90%;
  margin: auto;

  &[data-type="MatchTheCorrectWord"] {
    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;

      h1 {
        font-size: 24px;
      }
      .language_container {
        width: 60px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: white;
        border-radius: 9999px;
        filter: drop-shadow(0px 2.85714px 4.28571px rgba(0, 0, 0, 0.13));
      }
    }

    .middle {
      display: flex;
      gap: 12px;
      justify-content: center;
      margin-bottom: 96px;

      .question_container {
        max-width: 500px;
        margin: auto;
        .question {
          display: flex;
          flex-direction: column;
          gap: 8px;
          justify-content: center;
          align-items: center;
          background: #ffffff;
          border-width: 2px 4px 6px 4px;
          border-style: solid;
          border-color: #169fed;
          border-top-left-radius: 12px;
          border-top-right-radius: 12px;
          height: 68px;
          padding-bottom: 0;
        }
        &[data-show-answer="true"] {
          .answer {
            display: flex;
            flex-direction: column;
            gap: 8px;
            justify-content: center;
            align-items: center;
            border-bottom-left-radius: 12px;
            border-bottom-right-radius: 12px;
            height: 68px;
            padding-bottom: 0;
            color: white;
          }

          &[data-correct="true"] {
            .question {
              border-color: #0ac68b;
            }

            .answer {
              background-color: #0ac68b;
            }
          }
          &[data-wrong="true"] {
            .question {
              border-color: #ff5252;
            }
            .answer {
              background-color: #ff5252;
            }
          }
        }
      }
    }

    .options {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 500px;
      margin: auto;
      gap: 24px;
      margin-bottom: 64px;

      .option {
        display: flex;
        flex-direction: column;
        gap: 8px;
        justify-content: center;
        align-items: center;
        background: #169fed;

        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
        height: 68px;
        color: white;
        padding-bottom: 0;

        &[data-selected="true"] {
          border-color: #169fed;
        }

        &[data-correct="true"] {
          border-color: #09b27d;
        }
        &[data-wrong="true"] {
          border-color: #ff5252;
        }

        &[data-has-been-answered="true"] {
          color: white;
          p {
            color: white;
          }
        }

        p {
          color: white;
          font-size: 18px;
          margin: 0;
        }
      }
    }

    .check-btn {
      max-width: 400px;
      margin: auto;
    }
  }
}
