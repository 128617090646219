.dob-picker {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 8px;
  margin: 16px auto;
  width: 100%;
  max-width: 500px;
  padding: 16px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background: linear-gradient(145deg, #f8f9fa, #e9ecef);
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);

  .dob-label {
    font-size: 1rem;
    font-weight: 600;
    color: #495057;
  }

  .dob-input {
    width: 100%;
    padding: 10px 12px;
    font-size: 1rem;
    font-family: inherit;
    border: 1px solid #b7e1f9;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.05);
    transition: all 0.3s ease;

    &:hover {
      border-color: #adb5bd;
    }

    &:focus {
      outline: none;
      border-color: #b7e1f9;
      box-shadow: 0 0 4px rgba(76, 110, 245, 0.4);
    }
  }

  /* Styling the React Date Picker dropdowns */
  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

  .react-datepicker-popper {
    z-index: 10;
  }

  .react-datepicker {
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #fff;
  }

  .react-datepicker__header {
    background-color: #b7e1f9;
    color: #fff;
    border-bottom: 1px solid #ddd;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  .react-datepicker__current-month {
    font-size: 1rem;
    font-weight: 600;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected {
    background-color: #4c6ef5;
    color: #fff;
    border-radius: 50%;
  }

  .react-datepicker__day:hover {
    background-color: #e9ecef;
    border-radius: 50%;
  }
}
