.close-btn {
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: start;
  width: fit-content;
  color: #000;
}
.legal-links--container {
  display: grid;
  gap: 32px;
  padding: 20px;

  .heading {
    color: #808080;
    font-weight: 700;
    font-size: 1.2rem;
  }

  .links-list {
    display: grid;
    gap: 20px;

    .link-item {
      display: flex;
      align-items: center;
      padding: 12px 16px;
      gap: 20px;
      height: 80px;
      border: 2px solid #e1e8ed;
      border-radius: 12px;
      text-decoration: none;
      justify-content: space-between;
      align-items: center;

      &:hover {
        background-color: #f7f7f7;
      }

      .icon-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;
        background: #b7e1f9;
        border-radius: 50%;
      }

      .title {
        font-size: 16px;
        font-weight: 500;
        color: #000;
      }

      .sub-title {
        font-size: 14px;
        font-weight: 400;
        color: #808080;
      }
    }
  }
}
