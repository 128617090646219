@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

.forgot-password-header {
  display: flex;
  background-color: #fff;
  // padding-left: 120px;
  padding-left: 50px;
  align-items: center;
  height: 10vh;
}

.forgot-password-logo {
  width: 90px;
}
 
.forgot-password-pluritongues {
  color: #1491d8;
  font-weight: 700;
}

.forgot-password-layout {
  background-color: #ccc;
  display: flex;
  height: 90vh;
  width: 100vw;
}

.forgot-password-sidebar {
  width: 590px;
  background-color: #fff;
  // background-color: gray;
  padding: 30px 50px;

  h1 {
    text-align: center;
    margin-bottom: 10px;
    font-weight: 700;
    font-size: 35px;
  }

  p {
    color: #808080;
    margin-bottom: 50px;
    font-size: 16px;
  }
}

.forget-password-back-arrow-wrapper {
  display: flex;
  align-items: center;
  gap: 13px;
  margin-bottom: 80px;
  width: 100px;
  padding: 10px;
  border-radius: 8px;
  transition: transform 0.3s ease;
}

.forget-password-back-arrow-wrapper:hover{
  background-color: #4FADED;
  color: #fff;
  cursor: pointer;
}

.forget-password-back-arrow {
  font-size: 20px;
}

.forgot-password-back-text {
  font-weight: 700;
  font-size: 18px;
}

.forget-password-back-arrow-wrapper:hover .forgot-password-back-text{
  font-weight: 500;
}

.forget-password-form-wrapper {
  color: #808080;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 70px;
  font-family: Poppins, "sans-serif";
}

.forgot-password-error {
  color: red;
  font-family: Poppins, "sans-serif";
  font-size: 20px;
  text-align: center;
}

.forgot-password-footer {
  font-weight: 400;
  font-family: Poppins, "sans-serif";
  position: absolute;
  bottom: -40px;
  font-size: 16px;
  left: 5%;

  a {
    text-decoration: none;
    color: #1491d8;
  }
}

.forgot-password-bg-body {
  background-color: blue;
  flex: 1;
  background-image: url("../../image/bg-color.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

@media screen and (max-width: 430px) {
  .forgot-password-header {
    // padding-left: 0px;
    // justify-content: center;
    // margin: 0 auto;
    // justify-content: center;
  }

  .forgot-password-sidebar {
    padding: 30px;

    h1 {
      font-weight: 600;
      font-size: 32px;
    }
  }

  .forgot-password-back-text {
    font-weight: 600;
  }

  .forgot-password-footer {
    left: 14%;
  }
}
