.progress_bar--container {
  background: #e1e8ed;
  height: 24px;
  border-radius: 60px;
  width: 100%;

  .progress {
    background: #169fed;
    height: 100%;
    border-radius: 60px;
  }
}
