.tab_switch--container {
  display: flex;

  .tab {
    width: 100%;
    display: flex;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    padding: 10px;
    height: 44px;

    &[data-is-selected="true"] {
      border-bottom: 3px solid #1177b2;
    }
  }
}
