.question_container {
  width: 90%;
  margin: auto;

  // for bigger screens
  @media (min-width: 768px) {
  }
  &[data-type="SelectCorrectImageActivity"] {
    // call this top activity header
    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;

      h1 {
        font-size: 17px;
        font-weight: 600;
      }

      // some sort of icon btn
      .language_container {
        width: 42px;
        height: 42px;
        border-radius: 50%;
        padding: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: white;
        filter: drop-shadow(0px 2.85714px 4.28571px rgba(0, 0, 0, 0.13));
      }

      @media (min-width: 768px) {
        h1 {
          font-size: 24px;
        }

        .language_container {
          width: 60px;
          height: 60px;
          padding: 0;
        }
      }
    }

    // some other class
    .middle {
      display: flex;
      gap: 12px;
      align-items: center;
      margin-bottom: 32px;

      // some part of icon btn
      .audio_container {
        width: 42px;
        height: 42px;
        border-radius: 50%;
        padding: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #169fed;
        filter: drop-shadow(0px 2.85714px 4.28571px rgba(0, 0, 0, 0.13));

        audio {
          display: none;
        }
      }
      button.cantlisten {
        color: #808080;
        font-weight: 700;
      }

      @media (min-width: 768px) {
        .audio_container {
          width: 48px;
          height: 48px;
          padding: 0;
        }

        .button.cantlisten {
          font-weight: 600;
        }
      }
    }

    .options {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      max-width: 345px;
      margin: auto;
      gap: 26px;
      margin-bottom: 64px;

      .option {
        display: flex;
        flex-direction: column;

        align-items: center;
        background: #ffffff;
        border-width: 2px 4px 6px 4px;
        border-style: solid;
        border-color: #e1e8ed;
        border-radius: 49.359px;
        padding: 24px 24px 0 24px;

        &[data-selected="true"] {
          border-color: #169fed;
        }

        &[data-correct="true"] {
          border-color: #09b27d;
        }
        &[data-wrong="true"] {
          border-color: #ff5252;
        }

        p {
          color: black;
          font-size: 14px;
        }

        img {
          max-width: 80px;
          height: 80px;
          object-fit: contain;
          aspect-ratio: 1/1;
        }
      }
    }

    .check-btn {
      max-width: 400px;
      margin: auto;
    }

    // small screen
    @media (max-width: 767px) {
      .check-btn {
        position: absolute;
        bottom: 20px;
        width: 343px;
      }
    }

    // bigger screen
    @media (min-width: 768px) {
      .middle {
        margin-bottom: 0;
      }
      .options {
        max-width: 500px;
        gap: 48px;

        .option {
          p {
            color: black;
            font-size: 18px;
          }

          img {
            max-width: 112px;
            height: 112px;
            object-fit: contain;
            aspect-ratio: 1/1;
          }
        }
      }
    }
  }
}
