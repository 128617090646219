.question_container {
  width: 90%;
  margin: auto;
  &[data-type="VideoContentActivity"] {
    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;

      h1 {
        font-size: 24px;
      }

      .language_container {
        width: 60px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: white;
        border-radius: 9999px;
        filter: drop-shadow(0px 2.85714px 4.28571px rgba(0, 0, 0, 0.13));
      }
    }

    .middle {
      display: flex;
      gap: 12px;
      align-items: center;

      .video-player {
        // min-width: 600px;
        // height: auto;
        background-color: #f0f0f0;
        // margin: 10px auto;
        // position: relative;

        width: 100%; /* Or a fixed width, e.g., 400px */
        height: 0;
        padding-top: 111.11%; /* 10 / 9 * 100 */
        position: relative;

        .replay-button {
          display: flex;
          position: absolute;
          top: 20px;
          right: 10px;
          background-color: #169fed;
          color: white;
          border: none;
          border-radius: 20px;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          font-size: 14px;
          padding: 5px 10px;
          gap: 5px;
        }

        .video-frame {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 78vh;
        }
      }
    }

    .overlay-options {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 70%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      padding: 20px;
      background-color: white;
      transition: all 0.3s ease-in-out;
    }

    .options {
      display: flex;
      flex-direction: column;
      width: 600px;
      gap: 48px;
      margin: auto;

      .option {
        display: flex;
        flex-direction: column;
        align-items: center;
        background: #ffffff;
        border-width: 2px 4px 6px 4px;
        border-style: solid;
        border-color: #e1e8ed;
        border-radius: 49.359px;
        padding: 24px;

        &[data-selected="true"] {
          border-color: #169fed;
        }

        &[data-correct="true"] {
          border-color: #09b27d;
        }

        &[data-wrong="true"] {
          border-color: #ff5252;
        }

        p {
          color: black;
          font-size: 18px;
        }

        img {
          max-width: 112px;
          height: 112px;
          object-fit: contain;
          aspect-ratio: 1/1;
        }
      }
    }

    .check-btn {
      max-width: 400px;
      margin: auto;
    }

    @media (max-width: 768px) {
      .middle {
        .video-player {
          min-width: auto;
          max-width: 600px;
          width: 100%;
          height: 100%;
        }
      }
      .overlay-options {
        height: 65%;
        padding: 20px;
      }
      .options {
        min-width: auto;
        max-width: 600px;
        width: 100%;
        gap: 30px;

        .option {
          padding: 16px;
          border-width: 2px 3px 3px 3px;
          border-radius: 10px;

          p {
            font-size: 14px;
          }
        }
      }

      .check-btn {
        max-width: 400px;
        margin: 30px auto;
      }
    }
  }
}

@media (max-width: 768px) {
  .question_container {
    width: 95%;
  }
}
