.country-list-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  
  .heading {
    font-size: 1.5rem;
    font-weight: bold;
  }
  
  .list, .list:first-child {
    max-height: 600px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
      border-radius: 20px;
       border: 1px solid #ccc;
  }
  .list:last-child {
    border-bottom: 0;
  }
  
  .list-item {
    display: flex;
    width: 100%;
    align-items: center;
    padding: 1rem 0.5rem ;
    border-bottom: 1px solid #ccc;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .list-item:hover {
    background-color: #f5f5f5;
  }
  
  .list-item.selected {
    background-color: #d1e7ff;
    // border-color: #0d6efd;
  }
  
  .checkbox {
    margin-right: 0.5rem;
  }
  
  .list-item-text {
    flex-grow: 1;
  }

}
