.new-password-layout {
  background-color: #ccc;
  display: flex;
  height: 100vh;
  width: 100vw;
}

.new-password-sidebar {
  width: 590px;
  background-color: #fff;
  padding: 40px;
}

.new-password-sidebar-h1 {
  text-align: center;
  margin-bottom: 20px;
//   font-weight: 400;
  font-size: 35px;
}

.new-password-sidebar-p {
  // text-align: center;
  margin-bottom: 50px;
  color: #808080;
}

.new-password-form-wrapper {
  color: #808080;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 70px;
}

.new-password-label {
  font-size: 25px;
}

.new-password-input {
  border-radius: 0px;
  height: 60px;
  font-weight: 400;
  border: none;
  border-bottom: 2px solid #2d2d2d;
  font-size: 20px;
  outline: none;
  margin-bottom: 70px;
//   background-color: aquamarine;
}

.new-password-input::placeholder {
    font-size: 20px;
    padding-bottom: 20px;
    // background-color: yellow;
  }

.new-password-second-input {
  border-radius: 0px;
  height: 60px;
  font-weight: 400;
  border: none;
  border-bottom: 2px solid #2d2d2d;
  font-size: 20px;
  outline: none;
  color: black;
//   background-color: aquamarine;
}

.new-password-second-input::placeholder{
   font-size: 20px;
   padding-bottom: 20px;
//    background-color: yellow;
}

.new-password-show-toggle {
  position: absolute;
  top: 285px;
  left: 480px;
  cursor: pointer;
}

.new-password-show-second-toggle {
  position: absolute;
  top: 495px;
  left: 480px;
  cursor: pointer;
}

.new-password-error {
  color: red;
  font-size: 20px;
  text-align: center;
}

.new-password-btn-wrapper{
    display: flex;
    justify-content: center;
    width: 300px;
  margin-top: 340px;
  text-align: center;
}

.new-password-btn {
//   width: 100%;
// width: 300px;
  margin-top: 340px;
//    text-align: center;
}

.new-password-bg-body {
  background-color: #ccc;
  flex: 1;
  background-image: url("../../image/bg-color.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

@media screen and (max-width: 430px) {
  .new-password-sidebar-h1 {
    font-size: 25px;
    // font-weight: 500;
    text-align: left;
  }

  .new-password-show-toggle {
    // right: 500px;
    top: 300px;
    left: 320px;
  }

  .new-password-show-second-toggle {
    // right: 500px;
    top: 510px;
    left: 320px;
  }

  .new-password-bg-body {
    // display: none;
  }

  .new-password-btn {
    margin-top: 220px;
  }
}
