.dropdown {
  width: 100%;
  border: 2px solid #e1e8ed;
  border-radius: 8px;
  font-family: Arial, sans-serif;
  color: #000;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px;
    cursor: pointer;

    .dropdown__icon {
      transition: transform 0.3s ease;
    }

    .dropdown__icon.open {
      transform: rotate(180deg);
    }
  }

  &__list {
    list-style: none;
    margin: 0;
    padding: 0;
    border-top: 1px solid #e1e8ed;
    height: 200px;
    max-height: 300px;
    overflow: scroll;
  }

  &__item {
    padding: 12px 16px;
    border-bottom: 1px solid #e1e8ed;
    cursor: pointer;
    transition: background-color 0.2s;

    &:last-child {
      border-bottom: none;
    }

    &:hover {
      background-color: #e1e8ed;
    }
  }
}
