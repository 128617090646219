.enrolment--tabs {
  margin: 20px 0;
  &__close-btn {
    display: flex;
    gap: 12px;
    align-items: center;
    justify-content: start;
    width: fit-content;
    color: #000;
    margin-bottom: 20px;

    svg {
      color: #ff5252;
      transform: translateY(-1px);
    }
  }
  .enrolment-lesson-card {
    border-width: 2px 4px 6px 4px;
    border-style: solid;
    border-color: #e1e8ed;
    border-radius: 10.48px;
    display: flex;
    flex-direction: column;
    gap: 200px;
  }
  .enrolment--container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px 0;
    .enrolment-lesson-card {
      border-width: 2px 4px 6px 4px;
      border-style: solid;
      border-color: #e1e8ed;
      border-radius: 10.48px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      padding: 20px 10px;
      .lesson-title {
        font-size: 16px;
        font-weight: 700;
        color: #808080;
      }
      .lesson-description {
        font-size: 15px;
        font-weight: 400;
      }
      .enrolment-btn {
        font-weight: 500;
        font-size: 14px;
        background-color: #169fed;
        border-radius: 20px;
        padding: 10px 20px;
        max-width: 112px;
        border: none;
        color: #fff;
        align-self: self-end;
        cursor:pointer
      }
    }
  }
}
 .back-btn {
    display: flex;
    gap: 12px;
    align-items: center;
    justify-content: start;
    width: fit-content;
    color: #000;
  }