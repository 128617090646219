.notification-page {
  padding: 30px 50px;
}

/* Back Button */
.back-button {
  display: flex;
  align-items: center;
  gap: 5px;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 14px;
  color: #555;
  margin-bottom: 20px;
}

/* Page Title */
.page-title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #808080;
}

/* Notification Container */
.notification-container {
  display: flex;
  gap: 20px;
  background: #fff;
  overflow: hidden;
}

/* Notification List */
.notification-list {
  flex: 1;
  max-width: 350px;
  border: 2px solid #e1e8ed;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  min-height: 60vh;
}

.tabs {
  display: flex;
  border-bottom: 1px solid #eee;
}

.tab {
  flex: 1;
  padding: 10px;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
  background: transparent;
  border: none;
}

.tab.active {
  background: transparent;

  border-bottom: 2px solid #0a486b;
}

.notification-item {
  display: flex;
  padding: 10px;
  border-bottom: 1px solid #eee;
  cursor: pointer;
  transition: background-color 0.2s;
  // width: 100%;
}

.notification-item:hover {
  background: #b7e1f9;
}

.notification-item.active {
  background: #b7e1f9;
}

.notification-title {
  font-size: 16px;
  font-weight: bold;
  color: #000;
  margin-bottom: 5px;
}

.notification-content {
  font-size: 14px;
  color: #808080;
  margin-bottom: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.notification-date {
  font-size: 12px;
  color: #000;
  text-align: right;
}

/* Notification Details */
.notification-details {
  flex: 2;
  border: 2px solid #e1e8ed;
  border-radius: 10px;
  max-height: 70vh;
  scroll-behavior: smooth;
}

.details-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #00000066;
  padding: 20px;
}

.details-body {
  padding: 20px 100px;
}

.details-close {
  display: flex;
  align-items: center;
  gap: 2px;
}

.font-bold {
  font-weight: 700;
}

.details-title {
  font-size: 14px;
  font-weight: 400;
  color: #333;
}

.details-date {
  font-size: 14px;
  color: #999;
  margin-bottom: 15px;
  text-align: center;
}

.details-image {
  width: 100%;
  height: auto;
  max-height: 300px;
  margin-bottom: 20px;
  border-radius: 10px;
  object-fit: cover;
}

.details-content {
  font-size: 16px;
  color: #555;
  line-height: 1.5;
}

.image-container {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 15px;
  flex-shrink: 0;
}

.image-container > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.text-container {
  width: 60%;
}

@media (max-width: 1200px) {
  .details-body {
    padding: 20px 50px;
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .notification-container {
    flex-direction: column;
    overflow: scroll;
  }

  .notification-list {
    max-width: 100%;
    border-right: none;
    border-bottom: 1px solid #eee;
    min-height: 30vh;
  }

  .notification-item {
    flex-direction: row;
    align-items: flex-start;
  }

  .image-container {
    margin-right: 10px;
  }

  .notification-content {
    white-space: normal;
  }
}
