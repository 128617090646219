.question_container {
  width: 90%;
  margin: auto;

  &[data-type="TapInWhatYouHear"] {
    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;

      h1 {
        font-size: 24px;
      }
      .language_container {
        width: 60px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: white;
        border-radius: 9999px;
        filter: drop-shadow(0px 2.85714px 4.28571px rgba(0, 0, 0, 0.13));
      }
    }

    .middle {
      display: flex;
      gap: 12px;
      align-items: center;
      .audio_container {
        width: 48px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #169fed;
        border-radius: 9999px;
        filter: drop-shadow(0px 2.85714px 4.28571px rgba(0, 0, 0, 0.13));

        audio {
          display: none;
        }
      }
      button.cantlisten {
        color: #808080;
        font-weight: 700;
      }
    }

    .selected_options {
      border-bottom: 1px solid rgba(0, 0, 0, 0.4);
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      max-width: 500px;
      margin: 48px auto;

      .option_container {
        padding: 12px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.4);
        position: relative;
        top: 1px;

        .option {
          display: flex;
          flex-direction: column;
          align-items: center;
          background: #ffffff;
          border-width: 2px 4px 6px 4px;
          border-style: solid;
          border-color: #e1e8ed;
          border-radius: 12px;
          padding: 10px 16px;
          max-height: 48px;

          &[data-selected="true"] {
            border-color: #169fed;
          }

          &[data-correct="true"] {
            border-color: #09b27d;
          }
          &[data-wrong="true"] {
            border-color: #ff5252;
          }
        }
      }
    }

    .options {
      display: flex;
      justify-content: center;
      width: 100%;
      max-width: 500px;
      margin: auto;
      gap: 24px;
      flex-wrap: wrap;
      margin-bottom: 64px;

      .option {
        display: flex;
        flex-direction: column;
        align-items: center;
        background: #ffffff;
        border-width: 2px 4px 6px 4px;
        border-style: solid;
        border-color: #e1e8ed;
        border-radius: 12px;
        padding: 10px 16px;
        max-height: 48px;

        &[data-selected="true"] {
          border-color: #169fed;
        }

        &[data-correct="true"] {
          border-color: #09b27d;
        }
        &[data-wrong="true"] {
          border-color: #ff5252;
        }

        p {
          color: black;
          font-size: 18px;
        }
      }
    }

    .check-btn {
      max-width: 400px;
      margin: auto;
    }
  }
}
