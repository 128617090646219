.contact_us--container {
  display: grid;
  gap: 32px;

  .close-btn {
    display: flex;
    gap: 12px;
    align-items: center;
    justify-content: start;
    width: fit-content;
    color: #000;

    svg {
      color: #ff5252;
      transform: translateY(-1px);
    }
  }

  .heading {
    color: #808080;
    font-weight: 700;
    font-size: 1rem;
  }

  .btn-list {
    display: grid;
    gap: 20px;
          .social-link {
        width: 100%;
      }

    .btn-item {
      display: flex;
      align-items: center;
      padding: 12px 16px;
      justify-content: start;
      gap: 20px;
      height: 80px;
      border: 2px solid #e1e8ed !important;
      border-radius: 12px;
      width: 100%;


      .icon-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;
        background: #b7e1f9;
        border-radius: 65.4295px;
      }

      .title {
        font-size: 16px;
        font-weight: 500;
        color: #000;
        text-align: left;
        display: block;
      }

      .sub-title {
        font-size: 14px;
        font-weight: 400;
        color: #000;
      }
    }
  }
}
