.review-container {
  width: 100%;
  max-width: 600px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: transparent;
}

.review-header {
  margin-bottom: 20px;
}

.subtitle {
  font-size: 14px;
  color: #555;
}

.description {
  font-size: 16px;
  font-weight: bold;
}

.review-teacher-info {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.teacher-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}

.teacher-name {
  font-size: 16px;
  font-weight: bold;
}

.rating-container {
  display: flex;
  gap: 5px;
  margin-bottom: 20px;
  align-items: center;
  justify-content: center;
}

.star {
  font-size: 28px;
  cursor: pointer;
  background: none;
  border: none;
}

.star.filled {
  color: #f7cb15;
}

.review-textarea {
  width: 100%;
  height: 100px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 10px;
  resize: none;
}

.error-message {
  color: red;
  font-size: 14px;
  margin-bottom: 10px;
}

.submit-button {
  width: 20%;
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-self: flex-end;
  justify-self: flex-end;
  justify-content: center;
}

.submit-button:hover {
  background-color: #0056b3;
}

@media (max-width: 768px) {
  .submit-button {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-self: initial;
    justify-self: initial;
    justify-content: center;
  }
  .review-container {
    width: 100%;
    padding: 20px;
    border: none;
    border-radius: 8px;
    background-color: transparent;
  }
}
