.input_container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;

  .label {
    font-weight: 500;
    color: #808080;
  }

  input {
    display: flex;
    align-items: center;
    padding: 0 0.875rem;
    border-radius: 0;
    border: 1px solid #2d2d2d;
    // font-size: 0.875rem;
    // font-size: 14px;
    font-size: 16px; //handling text zoom
    height: 48px;
    max-height: 48px;
    font-weight: 500;
    color: rgba($color: #000000, $alpha: 0.6);
    border-width: 0 0 1px 0;

    &:focus {
      outline: none;
      border: 2px solid #169fed;
      border-width: 0 0 1px 0;
    }

    &:hover {
      border-color: #169fed;
      border-width: 0 0 1px 0;
    }

    &:disabled {
      opacity: 80%;
    }
  }
}
