.edit_profile--container {
  display: grid;
  gap: 32px;
  margin-bottom: 150px;

  .close-btn {
    display: flex;
    gap: 12px;
    align-items: center;
    justify-content: start;
    width: fit-content;
    color: #000;

    svg {
      color: #ff5252;
      transform: translateY(-1px);
    }
  }

  .heading {
    color: #808080;
    font-weight: 700;
    font-size: 1rem;
  }
}

.active-list-edit {
  .back-btn {
    display: flex;
    gap: 12px;
    align-items: center;
    justify-content: start;
    width: fit-content;
    color: #000;
    margin-bottom: 10px;
  }
}
