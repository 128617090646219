.date_select--container {
  margin-top: 20px;
  .date_select--time {
    margin-top: 20px;
  }

  .date_select--note {
    margin: 20px 0;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    color: #808080;
  }

  .date_select--footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .date_select--status {
      display: flex;
      align-items: center;
      gap: 8px;
      p {
        font-size: 15px;
        font-weight: 500;
        color: #808080;
      }
    }
  }
}
.enrolment-btn {
  font-weight: 500;
  font-size: 14px;
  background-color: #169fed;
  border-radius: 20px;
  padding: 10px 20px;
  max-width: 112px;
  border: none;
  color: #fff;
  display: flex;
  align-self: flex-end;
  justify-self: flex-end;
  &:hover {
    background-color: #0056b3;
  }

  &:disabled {
    background-color: #cccccc;
    color: #666666;
    cursor: not-allowed;
    opacity: 0.6;
  }
}
.selected-dates-container {
  margin: 40px 0;
}
.selected-dates-container-inner {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
