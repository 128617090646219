.activity-list-card {
  border-radius: 12px;
  border-width: 2px;
  border-color: #e1e8ed;
  border-style: solid;
  padding: 16px 28px;
  margin-bottom: 16px;
  display: grid;
  grid-row-gap: 12px;
}

.activity-list-card-image-icon {
  grid-column: 1/2;
  grid-row: 1/3;
}

.activity-list-card-header {
  grid-column: 2/4;
  grid-row: 1/2;
  font-size: 16px;
  font-weight: 500;
}

.activity-list-card-text {
  grid-column: 2/4;
  grid-row: 2/3;
  color: #808080;
  font-size: 14px;
  font-weight: 400;
}
