.container {
  display: grid;
  justify-content: center;
  align-content: center;
  height: 100vh;
}

.open-modal-button {
  padding: 10px 20px;
  background-color: #771520;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 300px;
}
.modal-content-options {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 500px;
}

.modal-content h2 {
  margin-bottom: 15px;
}

.modal-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

.modal-button {
  padding: 10px 20px;
  background-color: #771520;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
}

.modal-button:hover {
  background-color: #530f16;
}

.modal-close {
  margin-top: 5px;
  margin-bottom: 10px;
  padding: 8px 15px;
  background-color: #ccc;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-self: flex-end;
  justify-self: flex-end;
  font-size: 18px;
  color: rgb(206, 11, 11);
}
.options-container {
  display: flex;
  align-items: center;
  border: 1px solid #c4c4c4;
  border-radius: 10px;
  padding: 10px 15px;
  margin-top: 16px;
}
.balance-wallet-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 20px;
}
.wallet-text {
  font-weight: 500;
  font-size: 15px;
}
.wallet-text-card {
  font-weight: 500;
  font-size: 15px;
  margin-left: 20px;
}
.wallet-balance {
  font-weight: 400;
  font-size: 14px;
}
.total-price {
  font-weight: 400;
  font-size: 14px;
  color: #c4c4c4;
}
.total-profile-text {
  font-weight: 600;
  font-size: 14px;
  color: #000;
}
.wallet-payment-processing {
  padding: 30px;
  font-weight: 500;
  font-size: 16px;
}
