.wallet_container {
  display: grid;
  gap: 32px;
  width: 100%;

  .plusCircleButton {
    background-color: transparent;
    border: none;
    width: 60px;
    cursor: pointer;
  }
  .balance-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .hide_balance {
    font-weight: 500;
    color: #000;
    font-size: 16px;
    line-height: 0;
  }

  .wallet {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    min-width: 100%;

    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      padding: 20px 12px;
      min-width: 100%;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
      border-width: 3px 3px 3px 3px;
      border-style: solid;
      border-color: #e1e8ed;

      span {
        color: #000;
        font-weight: 500;
      }
    }

    & > .main {
      box-sizing: border-box;
      min-width: 100%;
      height: 160px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
      border-width: 0px 3px 3px 3px;
      border-style: solid;
      border-color: #e1e8ed;

      .row {
        display: flex;
        width: 100%;
        align-items: center;
        .left {
          flex: 1 1 0%;
          display: flex;
          justify-content: center;
          transform: translateX(16px);
        }
        svg {
          transform: translateX(-12px);
        }
      }
    }
  }
}
