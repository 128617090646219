.profile-btn {
  display: flex;
  justify-content: space-between !important;
  align-items: center !important;
  padding: 16px;
  border: 3px solid #e1e8ed !important;
  border-radius: 12px;

  .icon-container {
    border-radius: 9999px;
    background-color: #b7e1f9;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .right {
    display: flex;
    gap: 3px;
    align-items: center;
    font-weight: 500;
    color: #000;

    span {
      font-size: 16px;
    }
  }
}
