.checkbox-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
  border-radius: 4px;
  border: 1px solid #808080;
  background-color: #dadada;
  cursor: pointer;
  color: white;

  &[data-checked="true"] {
    background-color: #169fed;
    border-color: #169fed;
  }
}
