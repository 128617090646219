.profile_container {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  align-items: start;
  gap: 40px;
  padding-bottom: 80px;

  @media screen and (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  & > .left {
    display: grid;
    gap: 32px;
    .main_profile {
      border: 4px solid #e1e8ed;
      border-radius: 12px;
      padding: 28px 56px;
      display: flex;
      flex-direction: column;
      align-items: center;

      h1 {
        color: #000;
        font-weight: 600;
        font-size: 18px;
        text-align: center;
        margin: 20px 0;
      }

      .edit-profile-btn {
        position: relative;
        display: flex;
        justify-content: center;
        width: 80%;
        padding: 16px;
        height: 56px;
        border: 2px solid #e1e8ed;
        border-radius: 12px;

        span {
          color: black;
          font-weight: 500;
        }

        svg {
          position: absolute;
          right: 20px;
        }
      }
    }
  }

  & > .right {
    &[data-show="true"] {
      @media screen and (max-width: 768px) {
        padding: 20px;
        background-color: white;
        z-index: 10;
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        overflow-y: auto;
      }
    }
  }
}
