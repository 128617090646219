@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

.nav-sidebar {
  height: 100vh;
  border-right: 2px solid rgba(128, 128, 128, 0.4);
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

@media (max-width: 768px) {
  .nav-sidebar {
    display: none;
  }
}
