.bottom-link {
  text-decoration: none;
  .nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 0 16px 16px 12px;
  }
  .nav-item {
    display: flex;
    color: #808080;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.active-link {
  background-color: #b7e1f9;
}

.link:hover {
  background-color: #b7e1f9;
}

@media (min-width: 768px) {
  .bottom-link {
    display: none;
  }
}
