.lesson-card {
  display: flex;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 12px;
  width: 100%;
  min-height: 100px;
  background-color: #fff;
  color: #000000;
  font-family: Arial, sans-serif;
  gap: 15px;

  .lesson-card__content {
    display: flex;
    flex-direction: column;
    gap: 10px;
    text-align: flex-end;
    width: calc(100% - 60px);
  }
  .lesson-body{
    color: #000000;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
  .lesson-completed{
    color: #808080;
    font-weight: 400;
    font-size: 13px;
  }

  &__header {
     color: #000000;
    font-weight: 500;
    font-size: 15px;
  }

  &__avatar {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    object-fit: cover;
    background-color: #000000;
  }

  &__text {
    font-size: 14px;
    color: #ccc;
  }

  &__footer {
    font-size: 12px;
    color: #777;
    text-align: right;
  }
}
