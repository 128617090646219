.profile-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  min-width: 100%;

  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-height: 64px;
    padding: 20px 12px;
    min-width: 100%;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    border-width: 3px 3px 3px 3px !important;
    border-style: solid !important;
    border-color: #e1e8ed !important;

    span {
      color: #000;
      font-weight: 500;
    }
  }

  .main {
    box-sizing: border-box;
    min-width: 100%;
    padding: 0 12px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    border-width: 0px 3px 3px 3px;
    border-style: solid;
    border-color: #e1e8ed;

    input {
      padding: 16px 0px;
      border: none;
      outline: none;
      font-family: "Poppins";
      width: 100%;
      display: block;

      &:focus {
        outline: none;
      }
    }

    .row {
      display: flex;
      justify-content: start;
      padding: 12px 0;
      gap: 12px;
      flex-wrap: wrap;
      .profile-card--item {
        display: flex;
        gap: 10px;
        align-items: center;
        justify-content: center;
        border: 2px solid #e1e8ed;
        border-radius: 8px;
        color: #000;
        font-weight: 600;
        padding: 8px;
        font-size: 14px;
        height: 32px;
      }
    }
  }
}
.custom-datepicker {
  padding: 10px;
  border: 2px solid #e1e8ed;
  border-radius: 8px;
  font-size: 14px;
  width: 100%;
  box-sizing: border-box;

  &:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 4px rgba(0, 123, 255, 0.5);
  }
}
