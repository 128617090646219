.icon-and-text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 20px;
  width: 130px;
  gap: 15px;
  font-family: Poppins, sans-serif;
  font-weight: 500;
  color: #808080;
}

.active-link {
  background-color: #b7e1f9;
}

.active-link * {
  color: #169fed;
}

.link {
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  padding: 20px;
  margin: 20px;
  text-decoration: none;
  // height: 200px;
  font-size: 22px;
  border-radius: 12px;
}

.link:hover {
  background-color: #b7e1f9;
}
