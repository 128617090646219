.dashboard_layout--container {
  padding-top: 16px;
  .dashboard-children {
    padding: 16px;
    padding-bottom: 160px;
  }
}

@media (min-width: 769px) {
  .dashboard_layout--container {
    padding-top: 0;
    display: grid;
    grid-template-columns: minmax(200px, 270px) 1fr;

    & > .right {
      max-height: 100dvh;
      overflow-y: auto;
    }

    .dashboard-children {
      padding: 0;
      padding-left: 32px;
      padding-right: 32px;
      padding-top: 50px;
    }
  }
}
