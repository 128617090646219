.teachers_calendar--container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px 42px;
  gap: 28px;
  border: 4px solid #e1e8ed;
  border-radius: 12px;

  & > h1 {
    font-size: 16px;
    font-weight: normal;
    color: #808080;
  }

  .sessions {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 36px;

    .session {
      display: flex;
      gap: 12px;
      color: #808080;

      .circle {
        width: 20px;
        height: 20px;
        border-radius: 9999px;
      }

      &.available {
        .circle {
          background: #169fed;
        }
      }
      &.unavailable {
        .circle {
          background: #b3b3b3;
        }
      }
    }
  }

  .instruction {
    h1 {
      color: #000;
      font-size: 18px;
    }
    h3 {
      font-size: 16px;
      font-weight: normal;
      color: #808080;
    }
  }
}
