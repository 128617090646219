/* Chat Container */
.chat-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  border: 1px solid #eee;
  border-radius: 10px;
  overflow: hidden;
  background-color: #fff;
}

.chat-item-container {
  margin: 20px;
  height: 100px;
}

/* Chat Header */
.chat-header {
  padding: 15px;
  background: #f9f9f9;
  border-bottom: 1px solid #eee;
}

.chat-title {
  font-size: 18px;
  font-weight: bold;
  color: #333;
}

.details-close {
  display: flex;
  align-items: center;
  gap: 2px;
  cursor: pointer;
}

.font-bold {
  font-weight: 700;
}

/* Chat Body */
.chat-body {
  flex: 1;
  padding: 20px 100px;
  overflow-y: auto;
}

.chat-item {
  margin-bottom: 15px;
  display: flex;
  max-width: 70%;
  margin: 0 auto;
  gap: 10px;
}

.image-container {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #333;
}

.chat-message {
  padding: 10px 0;
  border-radius: 10px;
  font-size: 14px;
  color: #808080;
}

.chat-item.sent .chat-message {
  color: #808080;
}

.chat-timestamp {
  font-size: 12px;
  color: #999;
  margin-top: 5px;
}

.chat-date {
  text-align: center;
  color: #808080;
  font-size: small;
}

/* Chat Footer */
.chat-footer {
  display: flex;
  padding: 10px 15px;
  border-top: 2px solid #00000066;
  background: #ffffff;
  max-width: 100%;
}

.chat-input {
  flex: 1;
  padding: 10px;
  outline: none;
  font-size: 14px;
  border: none;
}

.chat-send {
  margin-left: 10px;
  padding: 2px 10px;
  max-height: 48px;
  background: #ffff;
  color: #169fed;
  border: 2.4px solid #e1e8ed;
  border-radius: 10px;
  font-size: 14px;
  cursor: pointer;
}
.notification-details {
  flex: 2;
  border: 2px solid #e1e8ed;
  border-radius: 10px;
  max-height: 70vh;
  scroll-behavior: smooth;
}

.chat-send:hover {
  border: 2.4px solid #169fed;
}

.details-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #00000066;
  padding: 20px;
}

.details-title {
  font-size: 14px;
  font-weight: 400;
  color: #333;
}

.chat-sender {
  font-weight: 500;
}

@media (max-width: 1200px) {
  .chat-item {
    max-width: 100%;
  }
  .chat-item-container {
    margin: 20px 0;
  }
  .chat-body {
    flex: 1;
    padding: 20px 50px;
    overflow-y: auto;
  }
}

@media (max-width: 768px) {
  .chat-container {
    border-radius: 0;
    height: auto;
  }
  .chat-content {
    max-width: 100%;
  }
  .chat-avatar {
    width: 30px;
    height: 30px;
  }
}

@media (max-width: 768px) {
  .chat-input {
    font-size: 12px;
    padding: 8px;
  }
  .chat-send {
    padding: 8px;
  }
  .send-icon {
    width: 20px;
    height: 20px;
  }
  .chat-body {
    padding: 20px 20px;
  }
  .chat-item-container {
    margin: 20px 10px;
  }
  .chat-item {
    max-width: 90%;
  }
}

@media (max-width: 640px) {
  .chat-body {
    padding: 20px 0;
  }
  .chat-item-container {
    margin: 20px 0;
  }
  .chat-item {
    max-width: 90%;
  }
}
