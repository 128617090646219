
.got-mail-layout{
    background-color: #ccc;
    display: flex;
    height: 100vh;
    width: 100vw;
}

.got-mail-sidebar{
   width: 590px;
   background-color: #fff;
   padding: 40px;

}

.got-mail-sidebar-h1{
    text-align: center;
    font-size: 35px;
    margin-bottom: 20px;
}

.got-mail-sidebar-p{
    font-size: 16px;
    color: #808080;
    margin-bottom: 90px;
}

.got-mail-input-row{
   display: flex;
   justify-content: space-between;
   margin-bottom: 100px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  display: none; /* Hides spinners in WebKit browsers */
}

.got-mail-single-input{
    width: 100px;
    height: 70px;
    background-color: #f2f2f2;
    border: none;
    outline: none;
    border-radius: 10px;
    text-align: center;
    font-size: 25px;

    // -webkit-appearance: none;
    // appearance: none;
//   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);


}

.got-mail-p{
    font-size: 16px;
    color: #808080;
    text-align: center;
    margin-bottom: 50px;
}

.got-mail-resend-code{
    font-size: 16px;
    text-align: center;
    color: #1491d8;
    // position: relative;
}



.got-mail-bg-body{
    background-color: #ccc;
    flex: 1;
    background-image: url("../../image/bg-color.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}


.got-mail-continue-btn{
    // background-color: yellow;
    height: 300px;
    margin-top: 390px;

}

@media screen and (max-width: 430px){
    .got-mail-sidebar{
        padding: 40px;
    }

    .got-mail-input-row{
        margin-bottom: 60px;
    }

    .got-mail-p{
        margin-bottom: 30px;
    }

    .got-mail-continue-btn{
        margin-top: 380px;
    }

    .got-mail-single-input{
        width: 60px;
        height: 50px;
    }

   
}