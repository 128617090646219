.report-container {
  padding: 50px 40px;
  background-color: #fff;
  width: 40%;
  border: 1px solid #b3b3b3;
  border-radius: 20px;
}

.loader {
  text-align: center;
  font-size: 16px;
  color: #808080;
}

.subtitle {
  font-size: 14px;
  line-height: 15px;
  font-weight: 600;
  color: #808080;
  margin-bottom: 20px;
}

.reasons-list {
  margin-bottom: 20px;
}

.reason-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 5px;
  cursor: pointer;
  margin-bottom: 10px;
  border-radius: 4px;
}

.radio {
  width: 20px;
  height: 20px;
  border: 2px solid #000;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.radio-inner {
  width: 12px;
  height: 12px;
  background-color: #169fed;
  border-radius: 50%;
}

.radio-inner.inactive {
  background-color: #fff;
}

.details-label {
  font-size: 13px;
  color: #808080;
  margin-bottom: 10px;
  display: block;
  font-weight: 500;
}

.details-input {
  width: 100%;
  padding: 10px;
  border-color: transparent;
  border-bottom: 2px solid #b3b3b3;
  border-radius: 4px;
  margin: 20px 0;
  resize: none;
}

.report-button {
  background-color: #cc4242;
  color: #fff;
  padding: 10px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 15px;
  align-self: flex-end;
  justify-self: flex-end;
  display: flex;
}

.report-button:disabled {
  background-color: rgb(209, 205, 205);
  cursor: not-allowed;
}

@media (max-width: 768px) {
  .subtitle {
    font-size: 12px;
    line-height: 14px;
    font-weight: 600;
    color: #808080;
    margin-bottom: 10px;
  }
  .report-container {
    padding: 20px;
    background-color: #fff;
    width: 100%;
    border: none;
  }
  .report-button {
    background-color: #cc4242;
    color: #fff;
    padding: 10px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 15px;
    align-items: center;
    justify-content: center;
    display: flex;
    width: 100%;
    text-align: center;
  }
  .reason-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0;
    cursor: pointer;
    margin-bottom: 10px;
    border-radius: 4px;
  }
}
