.stat-card {
  width: 89px;
  border: 1px solid #e1e8ed;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 12px 0;
  

  .stat-title {
    font-family: "Poppins", sans-serif;
    font-size: 8px;
    line-height: 10px;
    margin-bottom: 5px;
  }

  .stat-value {
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    line-height: 16px;
    margin-top: 10px;
  }
}
