.teachers-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: start;
  gap: 40px;
  padding-bottom: 80px;

  .teachers-left {
    display: grid;
    gap: 48px;
  }

  @media screen and (max-width: 1024px) {
    grid-template-columns: repeat(1, 1fr);
  }

  @media screen and (max-width: 640px) {
    .teachers-left{
      max-width: 92vw;
      display: flex;
      flex-direction: column;
    }
  }
}
