.general-settings {
  display: grid;
  gap: 32px;

  .heading {
    font-size: 16px;
  }

  .back-btn {
    display: flex;
    gap: 12px;
    align-items: center;
    justify-content: start;
    width: fit-content;
    color: #000;
  }

  .list {
    display: grid;
    gap: 24px;
  }

  p.email-title {
    &[data-verified="true"] {
      span {
        color: #0ac68b !important;
      }
    }
    &[data-verified="false"] {
      span {
        color: #ff5252;
      }
    }
  }
}

.active-setting-edit {
  .back-btn {
    display: flex;
    gap: 12px;
    align-items: center;
    justify-content: start;
    width: fit-content;
    color: #000;
  }
}
