.set_timezone--container {
  & > .timezone-list {
    margin-top: 32px;
    max-height: 400px;
    overflow-y: auto;
    & > .timezone {
      padding: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 72px;

      &:not(:first-of-type) {
        border-top: 1px solid #e1e8ed;
      }
    }
  }
}
