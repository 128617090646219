.avatar_container {
  width: 128px;
  height: 128px;
  background: #d9d9d9;
  position: relative;
  border-radius: 99999px;
  position: relative;
  background-size: cover; /* Scale the image to cover the container */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Prevent tiling */

  img {
    position: absolute;
    inset: 0;
    object-fit: cover;
    border-radius: 99999px;
  }

  .camera-icon {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #169fed;
    position: absolute;
    z-index: 5;
    bottom: 0px;
    right: 0px;
    border-radius: 99999px;
  }
}
