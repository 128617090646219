.resume-container{
    padding: 16px;
    .label{
        font-weight: 600;
        color: #808080;
        font-size: 14px;
        margin: 8px 0;
    }
    .checkbox {
        width: 15px;
        height: 15px;
        border: 3px solid #169FED;
        border-radius: 50%;
        background-color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: all 0.3s ease;
        margin-top: 11px;
      }
      .checkbox .checkmark {
        width: 7.5px;
        height: 7.5px;
        background-color: #169FED;
        border-radius: 50%;
        transition: all 0.3s ease;
      }
      .resume-container__point-container{
        display: flex;
        gap: 15px;
      }
      .experience{
        color: #808080;
        font-weight: 700;
        font-size:16px;
      }
      .job-title{
        margin-top: 8px;
        margin-bottom: 8px;
        color: #000000;
        font-size: 16px;
        font-weight: 400;
      }
      .title{
        margin-top: 15px;
      }
      .experience-title{
        margin-top: 14px;
        color: #808080;
      }

}