.intro-lessons {
  font-family: Arial, sans-serif;
  color: #000;
  padding: 20px;
  border-radius: 8px;
  width: 100%;

  &__title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 16px;
  }
  .intro-lessons__title {
    color: #808080;
    font-weight: 700;
    font-size: 16px;
  }
  .introduction {
    color: #000000;
    font-size: 15px;
    font-weight: 400;
    margin: 20px 0;
  }

  &__inputs {
    display: flex;
    gap: 12px;
    margin-bottom: 20px;

    .input-box {
      border: 1px solid #ccc;
      border-radius: 8px;
      text-align: center;
      cursor: pointer;
      transition: border-color 0.3s ease;
      width: 184px;
      height: 80px;
      flex: 1;
      padding: 20px 0;
      border-width: 1px 2px 4px 2px;

      &.input-box--selected {
        border-color: #169fed; 
      }
    }
  }
  .time {
    color: #000000;
    font-weight: 600;
    font-size: 16px;
  }

  &__cards {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}

.lesson-cards {
  height: 96px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-width: 1px 2px 4px 2px;
  border-style: solid;
  border-color: #169fed;
  height: 60px;
  color: #0078d4;
  border-radius: 10px;
  padding: 40px 0;

  &__discount {
    background-color: #0c5d8a;
    color: #fff;
    font-weight: 600;
    font-size: 12px;
    padding: 2px 6px;
    border-radius: 4px;
    width: fit-content;
    margin-bottom: 12px;
  }
}
.lesson-cards--inactive {
  height: 96px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-width: 1px 2px 4px 2px;
  border-style: solid;
  border-color: #e1e8ed;
  height: 60px;
  color: #0078d4;
  border-radius: 10px;
  padding: 40px 0;

  &__discount {
    background-color: #0c5d8a;
    color: #fff;
    font-weight: 600;
    font-size: 12px;
    padding: 2px 6px;
    border-radius: 4px;
    width: fit-content;
    margin-bottom: 12px;
  }
}

.lesson-cards__price {
  font-weight: 600;
  color: #169fed;
  font-size: 15px;
  width: 83px;
  font-size: 14px;
  font-weight: 600;
  margin-right: 10px;
}
.times {
  color: #000000;
  font-weight: 500;
  font-size: 15px;
  margin-left: 15px;
}

.enrolment-btn {
  margin-top: 20px;
  font-weight: 500;
  font-size: 14px;
  background-color: #169fed;
  border-radius: 20px;
  padding: 10px 20px;
  max-width: 112px;
  border: none;
  color: #fff;
  cursor:pointer;
}
.enrolment-btn:disabled{
  opacity: 0.5;
}
.btn {
  display: flex;
  justify-content: end;
}
