@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

.bottom-nav {
  display: flex;
  position: fixed;
  bottom: 0;
  z-index: 20;
  width: 100%;
  padding: 8px;
  box-shadow: 0px -1px 3px 0px #00000021;
  background-color: #fff;

  .navs {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  .nav-item {
    display: flex;
    color: #808080;
    //styleName: M3/label/medium - prominent;
    font-family: "Roboto", sans-serif;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0.5px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    padding: 12px 16px 16px 16px;
  }
}

.active-bottom-link * {
  color: #169fed;
}

@media (max-width: 768px) {
  .nav-sidebar {
    display: none;
  }
}
