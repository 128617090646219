.settings_container {
  display: grid;
  gap: 32px;

  .close-btn {
    display: flex;
    gap: 12px;
    align-items: center;
    justify-content: start;
    width: fit-content;
    color: #000;

    svg {
      color: #ff5252;
      transform: translateY(-1px);
    }
  }

  .heading {
    color: #808080;
    font-weight: 700;
    font-size: 1rem;
  }

  .btn-list {
    display: grid;
    gap: 20px;

    .btn-item {
      display: flex;
      align-items: center;
      padding: 12px 16px;
      justify-content: space-between;
      gap: 20px;
      height: 56px;
      border: 2px solid #e1e8ed !important;
      border-radius: 12px;

      .title {
        font-size: 16px;
        font-weight: 500;
        color: #000;
        text-align: left;
        display: block;
      }
    }
  }
}
