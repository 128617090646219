.go-back {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 20px;
}
.title {
  font-size: 16px;
  font-weight: 700;
}
.schedule-card--container-upcoming {
  border: 1px solid #e1e8ed;
  align-self: flex-start;
  padding: 20px 24px;
  width: 30%;
  border-radius: 10px;
  cursor: pointer;
}
.schedule-card--container-completed {
  border: 1px solid #169fed99;
  align-self: flex-start;
  padding: 20px 24px;
  width: 30%;
  border-radius: 10px;
  cursor: pointer;
}
.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}
.language {
  font-weight: 600;
  font-size: 14px;
  color: #1491d8;
}

.date {
  font-weight: 600;
  margin: 0;
  font-size: 18px;
}

.time {
  color: #808080;
  font-weight: 500;
  font-size: 16px;
}

@media (max-width: 768px) {
  .schedule-card--container-completed {
    width: 100%;
  }
  .schedule-card--container-upcoming {
    width: 100%;
  }
  .language {
    font-size: 12px;
  }

  .date {
    font-weight: 600;
    font-size: 14px;
  }

  .time {
    font-size: 14px;
  }
}
