.auth-layout--container {
  height: 100svh;
  display: grid;
  width: 100%;
  grid-template-columns: minmax(300px, 515px) 1fr;
  background: linear-gradient(198.21deg, #169fed 12.37%, #008753 91.74%);
  overflow-y: auto;

  .page {
    height: 100%;
  }
}
