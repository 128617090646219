.question_container {
  width: 90%;
  margin: auto;

  &[data-type="CrossWordActivity"] {
    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;

      h1 {
        font-size: 24px;
      }
      .language_container {
        width: 60px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: white;
        border-radius: 9999px;
        filter: drop-shadow(0px 2.85714px 4.28571px rgba(0, 0, 0, 0.13));
      }
    }

    .middle {
      display: grid;
      max-width: 500px;
      gap: 24px;
      margin: auto;
      grid-template-columns: repeat(2, 1fr);
      .questions {
        display: flex;
        flex-direction: column;
        gap: 24px;
        margin-bottom: 64px;

        .option {
          display: flex;
          flex-direction: column;
          gap: 8px;
          justify-content: center;
          align-items: center;
          background: #ffffff;
          border-width: 2px 4px 6px 4px;
          border-style: solid;
          border-color: #e1e8ed;
          border-radius: 12px;
          height: 100px;
          padding-bottom: 0;

          &[data-selected="true"] {
            border-color: #169fed;
          }

          &[data-correct="true"] {
            border-color: #09b27d;
          }
          &[data-wrong="true"] {
            border-color: #ff5252;
          }

          &[data-has-been-answered="true"] {
            color: #808080;
            p {
              color: #808080;
            }
          }

          p {
            color: black;
            font-size: 18px;
            margin: 0;
          }
        }
      }

      .options {
        display: flex;
        flex-direction: column;
        gap: 24px;
        margin-bottom: 64px;

        .option {
          display: flex;
          flex-direction: column;
          gap: 8px;
          justify-content: center;
          align-items: center;
          background: #ffffff;
          border-width: 2px 4px 6px 4px;
          border-style: solid;
          border-color: #e1e8ed;
          border-radius: 12px;
          height: 100px;
          padding-bottom: 0;

          &[data-selected="true"] {
            border-color: #169fed;
          }

          &[data-correct="true"] {
            border-color: #09b27d;
          }
          &[data-wrong="true"] {
            border-color: #ff5252;
          }

          &[data-has-been-answered="true"] {
            color: #808080;
            p {
              color: #808080;
            }
          }

          p {
            color: black;
            font-size: 18px;
            margin: 0;
          }
        }
      }
    }

    .check-btn {
      max-width: 400px;
      margin: auto;
    }
  }
}
