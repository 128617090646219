.summary-container {
  margin: 20px;
  border: 1px solid #c4c4c4;
  border-radius: 20px;

  .summary-container-header {
    padding: 20px;
    border-bottom: 1px solid #c4c4c4;
  }
  .summary-title {
    font-weight: 600;
    font-size: 15px;
  }
  .summary-desc {
    font-weight: 400;
    font-size: 13px;
  }

  .image-name-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 20px;

    .dpUrl {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-right: 15px;
    }
    .summary-name {
      font-weight: 400;
      font-size: 14px;
    }
  }
  .lesson-time-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
  }
  .lesson-time-text {
    font-weight: 600;
    font-size: 14px;
  }
  .summary-date {
    font-weight: 600;
    font-size: 14px;
  }
  .summary-time {
    font-weight: 400;
    font-size: 14px;
    color: #c4c4c4;
    margin-left: 10px;
  }
  .summary-date-container {
    padding: 20px;
    max-height: 250px;
  }
}
.summary-fee-container {
  margin: 0 10px;
  .summary-subtotal {
    font-weight: 600;
    font-size: 14px;
  }
  .summary-subtotal-amount {
    font-weight: 400;
    font-size: 14px;
    color: #c4c4c4;
  }
  .subtotal-tax-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 8px 20px;
  }
  .summary-btn {
    font-weight: 500;
    font-size: 14px;
    background-color: #169fed;
    border-radius: 20px;
    padding: 10px 20px;
    max-width: 112px;
    border: none;
    color: #fff;
    display: flex;
    align-self: flex-end;
    justify-self: flex-end;
    cursor: pointer;
    margin-top: 30px;
  }
}
